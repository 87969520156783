import React,{Component} from 'react';
import CountDown from 'reactjs-countdown';

// component
// import NewsLetter from "../../components/NewsLetter";
// import ImageSlideShow from "../../components/ImageSlideShow";
// import ContactForm from "../../components/ContactForm";
// import SocialLinks from "../../components/SocialLinks/SocialLinks";
import Logo from "../../components/Logo/Logo";

// images
import logo from '../../images/icon/eprezto-logo-bw.svg';
// import SliderImage1 from "../../images/bg/luxe_dressing_landing_bg.png";
// css
import './style.scss';

// const SliderImages = [
//     { url: SliderImage1 },
// ];

// const fadeProperties = {
//     duration: 3000,
//     transitionDuration: 1000,
//     infinite: true,
//     indicators: false,
//     arrows: false,
// };

export class ComingSoon extends Component{

    state = {
        open: ''
    };

    modalClose = () => {
        this.setState({
            open: ''
        })
    };
    modalShow = () => {
        this.setState({
            open: 'show'
        })
    };

    render() {
        return(
            <div className="comingSoon">
                {/*<div className="slideShowWrapper">
                    <ImageSlideShow
                        fadeProperties={fadeProperties}
                        sliderImages={SliderImages}
                    />
                </div>*/}
                {/*<SocialLinks/>*/}
                <div className="cmContent">
                <Logo url={logo} />
                    <h2>MUY PRONTO EN MÉXICO</h2>
                    {/*<p className="Nemo-enim-ipsam-volu">Our website us under construction, <br/>we are preparing something exceptional for you!</p>
                    <CountDown
                        end="October 22, 2019"
                    />
                    <NewsLetter />*/}
                </div>
                <div className="arrowLineDown"></div>
                {/*<div className="contactUs" onClick={this.modalShow}>Contact Us</div>
                <ContactForm
                    open={this.state.open}
                    modalClose={this.modalClose}
                />*/}
            </div>
        )
    }
}

export default ComingSoon;