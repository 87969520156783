import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import ComingSoon from "../ComingSoon";
import PageNotFound from "../../components/PageNotFound";

import './style.scss';

function App() {
  return (
      <BrowserRouter>
          <div className="App">
              <Switch>
                  <Route exact path="/" component={ComingSoon} />                 
                  <Route exact component={PageNotFound} />
              </Switch>
          </div>
      </BrowserRouter>

  );
}

export default App;
